.ql-editor {
    height:200px;
}
.text-editor-form-value{
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
}
.text-editor-form-error{
    border: 1px solid red;
    border-radius: 5px;
}
