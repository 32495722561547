.container {
    background-color: black;
}

.sub-container {
    min-Height: 100vh;
}

.app-logo {
    display: block;
    width: 70%;
    margin: 0 auto 40px;
}
