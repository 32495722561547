.speed-quiz-table {
    background-color: #ffffff;
    border-radius: 8px;
    color: rgb(18, 24, 40);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(100, 116, 139, 0.06) 0px 1px 1px, rgba(100, 116, 139, 0.1) 0px 1px 2px;
    overflow: hidden;
    border: none !important;
    padding: 16px
}
