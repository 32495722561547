.appRoot {
    display: flex;
}

/* 1. SideBar
 ********************** */

.app-sidebar {
    width: 280px;
}
.app-sidebar .logo {
    padding: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.app-sidebar .menu-item {
    margin: 0 40px 4px;
    border-radius: 8px;
    padding: 7px 24px 7px 40px;
}
.app-sidebar .menu-item.Mui-selected {
    color: #1468B6;
}
.app-sidebar .menu-item:hover,
.app-sidebar .menu-item.Mui-selected,
.app-sidebar .menu-item.Mui-selected:hover,
.app-sidebar .nested-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.12);
}
.app-sidebar .menu-item .MuiTypography-root,
.app-sidebar .nested-menu-item .MuiTypography-root {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.75;
}
.app-sidebar .nested-menu-item {
    margin: 0 16px 4px;
    border-radius: 8px;
    padding: 7px 24px;
}
.app-sidebar .nested-menu-item .MuiListItemIcon-root {
    min-width: 30px;
}
.app-sidebar .nested-menu-item .MuiListItemIcon-root svg {
    font-size: 20px;
}

/* 2. TopBar
 ********************** */

.app-topBar {
    width: calc(100% - 280px) !important;
    margin-left: 280px;
}
.app-topBar .text {
    flex-grow: 1;
}
.app-topBar .avatar {
    margin-left: 16px;
}
.app-topBar .logout-btn {
    color: rgba(0, 0, 0, 0.54);
}

/* 3. Content
 ********************** */

.page {
    width: calc(100% - 312px);
    padding: 16px;
    margin-top: 64px;
    background-color: #F9FAFC;
    min-height: calc(100vh - 96px);
}
.page .empty-page-text {
    font-size: 14px;
    font-style: italic;
    color: #dedfe1;
}
