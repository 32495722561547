.container-picture-input {
    border: 1px dashed #dddddd;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
}
img.adventure-image {
    max-width: 100%;
    height: auto;
    max-height: 200px;
    display: block;
    margin: 10px auto 15px;
}
.picture-input-title {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 10px;
}

.picture-input-subtitle {
    font-size: 13px !important;
    line-height: 17px;
    color: #6B7280;
    text-align: center;
    width: 100%;
    padding: 10px;
}
.picture-input-value {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 10px;
    padding-top: 8px;
}
.picture-input-error{
    border: 1px solid red;
}