.statistics-grid {
    background-color: #ffffff;
    border-radius: 8px;
    color: rgb(18, 24, 40);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(100, 116, 139, 0.06) 0px 1px 1px, rgba(100, 116, 139, 0.1) 0px 1px 2px;
    overflow: hidden;
}

.statistics-grid .block-title {
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 32px 24px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgb(230, 232, 240);
}

.statistics-grid .block-title .title {
    font-family: sans-serif;
    font-weight: 700;
    font-size: 15px;
    margin: 0;
}

.statistics-grid .block-title svg {
    color: rgb(107, 114, 128);
}

.statistics-grid.card {
    padding: 24px 32px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 100px;
}

.statistics-grid.card-chart p {
    color: #35A318;
    font-size: 34px;
    font-weight: 700;
}

.statistics-grid.card-chart span {
    color: #65748b;
    font-size: 14px;
    line-height: 17px;
}

.statistics-grid.card-text .d-flex {
    display: flex;
    align-items: center;
}

.statistics-grid.card-text p {
    color: #455a64;
    font-size: 38px;
    font-weight: 700;
    margin-right: 24px;
}

.statistics-grid.card-text span {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
}

.statistics-grid.card-text .light-style {
    font-size: 16px;
    display: block;
}

.inter-agency-goal .statistics-grid.card-text p {
    font-size: 30px;
    line-height: 30px;
    margin-right: 15px;
    letter-spacing: 0;
}

.inter-agency-goal .statistics-grid.card {
    padding: 25px 20px;
}

.inter-agency-goal .statistics-grid.card-text span {
    font-size: 18px;
    line-height: 22px;
}
.inter-agency-goal .statistics-grid.card-chart p {
    font-size: 32px;
    letter-spacing: 0;
}
.inter-agency-goal .statistics-grid.card-chart span {
    font-size: 13px;
    line-height: 16px;
    display: block;
}
.statistics-grid.card-chart .light-style {
    font-size: 11px;
    display: block;
    line-height: 16px;
    margin-bottom: 5px;
    letter-spacing: 0;
}

.statistics-grid.card-text .light-style b {
    font-size: 18px;
    font-weight: 700;
    margin: 0 5px;
}

.statistics-grid.card-chart .light-style b {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    margin-right: 5px;
    letter-spacing: -0.5px;
}

.statistics-grid.card-chart span.bold-style {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.1px;
}

.custom-tooltip {
    width: 200px;
    background-color: #eeeeee;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    line-height: 15px;
    color: #666666;
}

.statistics-grid.card-text {
    flex-wrap: wrap;
    justify-content: center;
}

.MuiLinearProgress-bar {
    background-color: #da1c05 !important;
}

.badge-image {
    background: radial-gradient(circle, rgba(61, 80, 185, 0.5) 25%, rgba(75, 93, 197, 0.3) 50%, rgba(255, 255, 255, 70%) 75%);
    width: 40px !important;
    height: 40px !important;
}
