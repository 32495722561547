.MuiAccordion-root.speed-quiz-accordion {
    background-color: #ffffff;
    border-radius: 8px !important;
    color: rgb(18, 24, 40);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(100, 116, 139, 0.06) 0px 1px 1px, rgba(100, 116, 139, 0.1) 0px 1px 2px;
    overflow: hidden;
    margin-bottom: 16px;
}
.MuiAccordion-root.speed-quiz-accordion .MuiAccordionSummary-root {
    padding: 16px 24px;
}
.MuiAccordion-root.speed-quiz-accordion .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-expandIconWrapper {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.MuiAccordion-root.speed-quiz-accordion .MuiAccordionSummary-content p {
    font-weight: 600;
}
.MuiAccordion-root.speed-quiz-accordion .MuiAccordionSummary-content span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 16px;
}
.MuiAccordion-root.speed-quiz-accordion .MuiAccordionDetails-root {
    border-top: 1px solid rgb(230, 232, 240);
    padding: 16px;
}
.MuiAccordion-root.speed-quiz-accordion .MuiFormControlLabel-label {
    font-size: 14px;
}
.MuiAccordion-root.speed-quiz-accordion .form-block-title {
    font-weight: 600;
    font-size: 15px;
    padding-left: 10px;
    padding-top: 16px;
}
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: rgb(18, 24, 40);
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgb(18, 24, 40) !important;
}
