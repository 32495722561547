body {
    margin: 0;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.page-loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
